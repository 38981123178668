import React from 'react';
import logo from '../images/logo.png';
/*import { hot } from 'react-hot-loader'*/
import '../App.css';
import moment from 'moment';
import 'moment/locale/es';
import history from '../history';
import { observer } from 'mobx-react';
import authAxios from '../utils/authAxios';
import {AuthToken} from '../utils/authToken';
import {LocalData} from '../utils/localData';
import {
  Button,
  Row,
  Col,
  Tooltip,
  DatePicker,
  Select,
  Drawer,
  TimePicker,
  Icon,
  Input,
  AutoComplete,
  Card,
  Skeleton,
  Avatar,
  Table,
  Pagination
} from 'antd';
import ReporteComponent from '../components/ReporteComponent';
import AuthService from '../services/authService';


const { Option } = AutoComplete;
const { Meta } = Card;

interface MyState {
  data: any,
  loading: boolean,
  selectedTenant: any
}
class Reportes extends React.Component<any, MyState> {

  constructor(props) {

    super(props);

    const user = LocalData.get('current_user');
    console.log('%c⧭ constructor', 'color: #ff6600', user);
    this.state = {
      data: null,
      loading: false,
      selectedTenant: (LocalData.get('selectedTenant') || this.firstRoleTenant(user).tenant)._id
    }
  }
  firstRoleTenant = (user) => user.tenants.find(t => t.roles.some(r => ['gerenteNacional', 'jefeSucursal'].includes(r)));

  home = () => {
    const location = {
      pathname: '/',
    }
    history.push(location);
  }

  goReporte = (item) => {
    const location = {
      pathname: '/' + item.url,
    }
    history.push(location);
  }
  
  logout(){
    AuthService.signout();
    console.log("logout");
    this.rerender();
  }
  rerender = () => {
    console.log('%c⧭ rerender', 'color: #00ff88', 'probando');
    this.props.rerender();
  }

  render() {
    const {selectedTenant} = this.state;
    const user = LocalData.get('current_user');
    LocalData.set('gerenteNacional',false);
    console.log('%c⧭selectedTenant', 'color: #40fff2', selectedTenant);
    LocalData.set('selectedTenant',(user.tenants.find((t)=>t.tenant._id==selectedTenant)|| {tenant:null}).tenant );
    let gerenteNacional = false;
    let roles = [];    
    user.tenants.some((tenant)=>{
      //console.log('%c⧭', 'color: #cc0036', tenant.tenant._id, selectedTenant._id);
      if(tenant.roles.includes('gerenteNacional')){
        gerenteNacional = true;
        LocalData.set('gerenteNacional',true);
        LocalData.set('selectedTenant',tenant.tenant);
        //console.log('%c⧭ tenant roles', 'color: #d0bfff', tenant.roles);
        roles = tenant.roles;
        return true;
      }
      if(tenant.tenant._id == selectedTenant){
        console.log('%c⧭ tenant roles', 'color: #d0bfff', tenant.roles);
        roles = tenant.roles;
      }
    });
    const tenants_options = user.tenants.filter(t=>t.roles.length).map((t)=>t.tenant);
    console.log('%c⧭ tenants_options', 'color: #607339', tenants_options);
    console.log('%c⧭ selectedTenant', 'color: #607339', selectedTenant);
    const reportes_urls = [
      // {roles:['gerenteNacional'],url:"reporte-atenciones-resumen-nacional",nombre:"Estado Atenciones Resumen"},
      {roles:['gerenteNacional'],url:"reporte-atenciones-detallado-nacional",nombre:"Estado Atenciones"},
      {roles:['jefeSucursal'],url:"reporte-atenciones-sucursal",nombre:"Estado Atenciones"},
      {roles:['gerenteNacional'],url:"reporte-desempeno-nacional",nombre:"Desempeño Sucursales"},
      {roles:['jefeSucursal'],url:"reporte-desempeno-sucursal",nombre:"Desempeño Sucursales"},
      // {roles:['gerenteNacional'],url:"reporte-cliente-resumen-nacional",nombre:"Preferencias Cliente Resumen"},
      {roles:['gerenteNacional'],url:"reporte-cliente-detallado-nacional",nombre:"Preferencias Cliente"},
      {roles:['jefeSucursal'],url:"reporte-cliente-sucursal",nombre:"Preferencias Cliente"},
      {roles:['gerenteNacional'],url:"reporte-horario-punta",nombre:"Horarios Punta"},
      {roles:['jefeSucursal'],url:"reporte-horario-punta-sucursal",nombre:"Horarios Punta"},
      // {roles:['jefeSucursal'],url:"reporte-horario-punta-sucursal",nombre:"Horarios Punta"},
    ].filter((url)=>url.roles.some((rol)=>roles.includes(rol)));

    return (
      <div className="App">
        <div className="Rooms-Home">
          <Row className="App-Header-Rooms">
            <Col lg={{ offset: 1, span: 11 }} xs={{ offset: 1, span: 11 }} >
              <Row className="logoHeader" type="flex" justify="start" align="middle">
                <label onClick={this.logout.bind(this)} style={{ color: 'white' }}><Icon style={{ color: 'white' }} type="arrow-left" /> Cerrar sesión</label>
              </Row>
            </Col>
            <Col lg={{ span: 11 }} xs={{ span: 11 }} >
              <Row className="logoHeader" type="flex" justify="end" align="middle">
                <a target="_BLANK" href="https://www.nomasfilas.cl"><img onClick={this.home} alt="LOGO" style={{ width: '15em', padding: 30 }} src={logo} /></a>
              </Row>
            </Col>
          </Row>
          <br />
          <br />
          <div style={{marginLeft:70}}>
            {gerenteNacional ? <h1>Gerente Nacional</h1> : <h1>Jefe {(tenants_options.find((t) => t._id == selectedTenant) || {name:''}).name}</h1>}
            {(!gerenteNacional && tenants_options.length > 1) ? <Select
              placeholder={"Seleccionar sucursal"}
              onChange={(tenant) => {
                //console.log('%c⧭', 'color: #5200cc', tenant);
                this.setState({ selectedTenant: tenant });
              }}
              defaultValue={selectedTenant}
            >
              {tenants_options.map(tenant => (<Select.Option key={tenant._id} value={tenant._id}>{tenant.name}</Select.Option>))}
            </Select>:null}
          </div>
          <Card style={{margin:30}}>
            <Row>
              {
                reportes_urls.map((r, i) => {
                  return (
                    <Col xs={{ span: 24 }} style={{ padding: 10 }} key={'col'+i} >
                      <span onClick={() => this.goReporte(r)} style={{ cursor:"pointer", fontSize: 17, border: "2px solid lightgray", padding:5, }}>{r.nombre}</span>
                    </Col>
                  );
                })
              }
            </Row>
          </Card>
          <br />
          <br />
        </div>
      </div >
    );

  }
}


export default observer(Reportes);
