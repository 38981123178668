import React from 'react';
import logo from '../../images/logo.png';
/*import { hot } from 'react-hot-loader'*/
import '../../App.css';
import moment from 'moment';
import 'moment/locale/es';
import history from '../../history';
import { observer } from 'mobx-react';
import authAxios from '../../utils/authAxios';
import {
  Button,
  Row,
  Col,
  Tooltip,
  DatePicker,
  Select,
  Drawer,
  TimePicker,
  Icon,
  Input,
  AutoComplete,
  Card,
  Skeleton,
  Avatar,
  Table,
  Pagination
} from 'antd';
import ReporteComponent from '../../components/ReporteComponent';


const { Option } = AutoComplete;
const { Meta } = Card;

interface MyState {
  data: any,
  loading: boolean,
  valueSearch: string,
  dataSearch: any[],
}
class ReporteClienteResumenNacional extends React.Component<any, MyState> {
  titulo_reporte = "Reporte preferencias y comportamientos cliente";
  url_reporte = '/reportes/' + 'cliente-resumen-nacional';
  columnas = [
    {
      title: 'Sucursal',
      key: 'sucursal',
      span:12,
    },
    {
      title: 'Valorización',
      key: 'tipo_reporte',
      span:6,
    },
    {
      title: 'TOTAL',
      key: 'total_v',
      span:6,
    }
  ];
  filtros = [
    {
      text: "Sucursales",
      placeholder: "Todas",
      type: "select",
      itemUrl: "sucursales-cliente?[filter][tipos_atencion]=1",//&[filter][funcionarios]=1",
      itemKey: "sucursales",
      itemText: (item) => (item.name),
      selectHandle: (filters, filtro_data) => {
        var items = filters.sucursales ? filters.sucursales : [];
        var tipos_atencion = [];
        if (filtro_data.sucursales) {
          filtro_data.sucursales.forEach(sucursal => {
            console.log("sucursal", sucursal);
            if (items.length > 0) {
              items.forEach(id_sel => {
                if (sucursal._id == id_sel) tipos_atencion = [...tipos_atencion, ...sucursal.tipos_atencion];
              });
            } else {
              tipos_atencion = [...tipos_atencion, ...sucursal.tipos_atencion];
            }
          })
        }
        console.log("tipos_atencion", tipos_atencion);
        filtro_data.tipos_atencion = tipos_atencion;
        return { filters, filtro_data };
      }
    },
    {
      text: "Fecha de inicio",
      type: "datepicker",
      itemKey: "fecha_inicio",
    },
    {
      text: "Fecha de término",
      type: "datepicker",
      itemKey: "fecha_termino",
    },
    {
      text: "Tipo de Atención",
      type: "select",
      itemKey: "tipos_atencion",
      itemText: (item)=>(item.nombre)
    },
    {
      text: "Tipo de Reporte",
      type: "select",
      itemKey: "tipos_reporte",
      itemData: [
        { _id: "valorizacion", nombre: "Valorización" },
        { _id: "cancelados", nombre: "Cancelados" },
        { _id: "visualizacion", nombre: "Visualizaciones" }
      ],
      itemText: (item)=>(item.nombre)
    }
  ]
  constructor(props) {

    super(props);

    this.state = {
      data: null,
      loading: false,
      valueSearch: "",
      dataSearch: [],
    }
  }

  home = () => {
    const location = {
      pathname: '/reportes',
    }

    history.push(location);
  }

  dibujarValorizacion = (num: Number) => {
    let str = "";
    for (let step = 0; step < num; step++) {
      str += "*";
    }
    return str;
  }

  render() {
    const Option = Select.Option;
    const { loading, data, dataSearch } = this.state;

    const valorizaciones = ["*", "**", "***", "****", "*****"];

    const dataFunc = (data, filters = {}) => {
      if (!data || data.length==0) return [];
      let data_rows: any = [];
      let row: any = {}
      /**
       * Reporte Valorizacion
       */
      if ('valorizacion' in data[0]) {
        row.sucursal = 'Sucursal';
        row.tipo_reporte = 'Valorización';
        row.total_v = 'TOTAL';
        row.header = true;
        data_rows.push(row);
        row = {}
        let total_atenciones = 0;
        data.forEach((sucursal) => {
          let total_s = 0;
          valorizaciones.forEach((val, i) => {
            row.sucursal = i == 0 ? sucursal.name : '';
            row.tipo_reporte = val;
            row.total_v = 0;
            sucursal.valorizacion.forEach(valorizacion => {
              if (valorizacion._id && valorizacion._id - 1 == i) {
                //row.valorizacion = val._id?this.dibujarValorizacion(val._id):'Sin valorización';
                row.total_v = valorizacion.total;
                total_s += valorizacion.total;
              }
            })
            data_rows.push(row);
            row = {}
          });
          row.sucursal = 'TOTAL ' + sucursal.name.toUpperCase();
          row.total_v = total_s;
          total_atenciones += total_s;
          row.total = true;
          row.total_span = {sucursal:18,tipo_reporte:0}
          data_rows.push(row);
          row = {}
        });
        row.sucursal = 'TOTAL ATENCIONES';
        row.total_v = total_atenciones;
        row.total_azul = true;
        row.total_span = {sucursal:18,tipo_reporte:0}
        data_rows.push(row);
        row = {}
      }
      /**
       * Reporte Cancelados
       */
      if ('cancelados' in data[0]) {
        row.sucursal = 'Sucursal';
        row.tipo_reporte = 'Cancelados';
        row.total_v = 'TOTAL';
        row.header = true;
        data_rows.push(row);
        row = {}
        let total_cancelados = 0;
        data.forEach((sucursal) => {
          let row: any = {}
          row.sucursal = sucursal.name;
          row.total_v = sucursal.cancelados;
          total_cancelados += sucursal.cancelados;
          data_rows.push(row);
          row = {}
        });
        row.sucursal = 'TOTAL CANCELADOS';
        row.total_v = total_cancelados;
        row.total_azul = true;
        data_rows.push(row);
        row = {}
      }
      /**
       * Reporte Cancelados
       */
      if ('visualizacion' in data[0]) {
        row.sucursal = 'Sucursal';
        row.tipo_reporte = 'Visualizacion';
        row.total_v = 'TOTAL';
        row.header = true;
        data_rows.push(row);
        row = {}
        let total_visualizaciones = 0;
        data.forEach((sucursal) => {
          row.sucursal = sucursal.name;
          row.total_v = sucursal.visualizacion;
          total_visualizaciones += sucursal.visualizacion;
          data_rows.push(row);
          row = {}
        });
        row.sucursal = 'TOTAL VISUALIZACIONES';
        row.total_v = total_visualizaciones;
        row.total_azul = true;
        data_rows.push(row);
      }
      return data_rows;
    }

    return (
      <div className="App">
        <div className="Rooms-Home">
          <Row className="App-Header-Rooms">
            <Col lg={{ offset: 1, span: 11 }} xs={{ offset: 1, span: 11 }} >
              <Row className="logoHeader" type="flex" justify="start" align="middle">
                <label onClick={this.home} style={{ color: 'white' }}><Icon style={{ color: 'white' }} type="arrow-left" /> Volver Atrás</label>
              </Row>
            </Col>
            <Col lg={{ span: 11 }} xs={{ span: 11 }} >
              <Row className="logoHeader" type="flex" justify="end" align="middle">
                <a target="_BLANK" href="https://www.nomasfilas.cl"><img onClick={this.home} alt="LOGO" style={{ width: '15em', padding: 30 }} src={logo} /></a>
              </Row>
            </Col>
          </Row>
          <br />
          <br />
          <div className="titulo-reporte">Reporte Preferencias y Comportamientos del Cliente</div>
          <br />
          <div className="subtitulo-reporte">Vista Gerente Nacional Resumen</div>
          <Col lg={{span:17}} md={{span:21}} xs={{span:24}} style={{padding:50,paddingLeft:"6vw"}}>
            <ReporteComponent showHeaders={false} dataFunc={dataFunc} url_reporte={this.url_reporte} titulo_reporte={this.titulo_reporte} columnas={this.columnas} filtros={this.filtros} />
          </Col>
        </div>
      </div >
    );

  }
}


export default observer(ReporteClienteResumenNacional);
