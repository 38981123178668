import React from 'react';
import logo from '../../images/logo.png';
/*import { hot } from 'react-hot-loader'*/
import '../../App.css';
import moment from 'moment';
import 'moment/locale/es';
import history from '../../history';
import { observer } from 'mobx-react';
import authAxios from '../../utils/authAxios';
import {
  Button,
  Row,
  Col,
  Tooltip,
  DatePicker,
  Select,
  Drawer,
  TimePicker,
  Icon,
  Input,
  AutoComplete,
  Card,
  Skeleton,
  Avatar,
  Table,
  Pagination
} from 'antd';
import ReporteComponent from '../../components/ReporteComponent';


const { Option } = AutoComplete;
const { Meta } = Card;

interface MyState {
  data: any,
  loading: boolean,
  valueSearch: string,
  dataSearch: any[],
}
class ReporteAtencionesDetalladoNacional extends React.Component<any, MyState> {
  titulo_reporte = "Reporte online atenciones Banco Estado";
  url_reporte = '/reportes/' + 'atenciones-detallado-nacional';
  columnas = [
    {
      title: 'Sucursal',
      key: 'sucursal',
      columnaText: (item) => (item.sucursal ? item.sucursal.name : ''),
    },
    {
      title: 'Tipo de atención',
      key: 'nombre',
    },
    {
      title: 'Atendiendo',
      key: 'atendiendo',
    },
    {
      title: 'En espera',
      key: 'en_espera',
    }
  ];
  filtros = [
    {
      text: "Sucursales",
      placeholder: "Todas",
      type: "select",
      itemUrl: "sucursales-cliente?[filter][tipos_atencion]=1",//&[filter][funcionarios]=1",
      itemKey: "sucursales",
      itemText: (item) => (item.name),
      selectHandle: (filters, filtro_data) => {
        var items = filters.sucursales ? filters.sucursales : [];
        var tipos_atencion = [];
        if (filtro_data.sucursales) {
          filtro_data.sucursales.forEach(sucursal => {
            console.log("sucursal", sucursal);
            if (items.length > 0) {
              items.forEach(id_sel => {
                if (sucursal._id == id_sel) tipos_atencion = [...tipos_atencion, ...sucursal.tipos_atencion];
              });
            } else {
              tipos_atencion = [...tipos_atencion, ...sucursal.tipos_atencion];
            }
          })
        }
        console.log("tipos_atencion", tipos_atencion);
        filtro_data.tipos_atencion = tipos_atencion;
        return { filters, filtro_data };
      }
    },
    {
      text: "Tipo de Atención",
      type: "select",
      itemKey: "tipos_atencion",
      itemText: (item) => (item.nombre + ' (' + item.tenant.name + ')')
    }
  ]
  constructor(props) {

    super(props);

    this.state = {
      data: null,
      loading: false,
      valueSearch: "",
      dataSearch: [],
    }
  }

  home = () => {
    const location = {
      pathname: '/reportes',
    }

    history.push(location);
  }

  render() {

    const dataFunc = (data, filters = {}) => {
      if (!data) return [];
      let data_rows: any = [];
      var id_sucursal = null;
      let totales = {};
      let total_final = {
        sucursal: { name: `TOTAL GENERAL` },
        total: true,
        atendiendo: 0,
        en_espera: 0,
        total_azul: true,
        total_span: { sucursal: 12, nombre: 0 }
      }
      data.forEach((tipo_atencion) => {
        if (id_sucursal != tipo_atencion.sucursal._id) {
          if (id_sucursal) data_rows.push(totales);
          totales = {
            sucursal: { name: `TOTAL ${tipo_atencion.sucursal.name.toUpperCase()}` },
            total: true,
            atendiendo: 0,
            en_espera: 0,
            total_span: { sucursal: 12, nombre: 0 }
          }
        } else {
          tipo_atencion.sucursal.name = '';
        }
        totales.atendiendo += tipo_atencion.atendiendo;
        totales.en_espera += tipo_atencion.en_espera;
        total_final.atendiendo += tipo_atencion.atendiendo;
        total_final.en_espera += tipo_atencion.en_espera;
        data_rows.push(tipo_atencion);
        id_sucursal = tipo_atencion.sucursal._id;
      });
      data_rows.push(totales);
      data_rows.push(total_final);
      return data_rows;
    }

    return (
      <div className="App">
        <div className="Rooms-Home">
          <Row className="App-Header-Rooms">
            <Col lg={{ offset: 1, span: 11 }} xs={{ offset: 1, span: 11 }} >
              <Row className="logoHeader" type="flex" justify="start" align="middle">
                <label onClick={this.home} style={{ color: 'white' }}><Icon style={{ color: 'white' }} type="arrow-left" /> Volver Atrás</label>
              </Row>
            </Col>
            <Col lg={{ span: 11 }} xs={{ span: 11 }} >
              <Row className="logoHeader" type="flex" justify="end" align="middle">
                <a target="_BLANK" href="https://www.nomasfilas.cl"><img onClick={this.home} alt="LOGO" style={{ width: '15em', padding: 30 }} src={logo} /></a>
              </Row>
            </Col>
          </Row>
          <br />
          <br />
          <div className="titulo-reporte">Reporte Online de Atenciones</div>
          <br />
          <div className="subtitulo-reporte">Vista Gerente Nacional Detallada</div>
          <div style={{ padding: 50, paddingLeft: "6vw" }}>
            <ReporteComponent dataFunc={dataFunc} url_reporte={this.url_reporte} titulo_reporte={this.titulo_reporte} columnas={this.columnas} filtros={this.filtros} />
          </div>
        </div>
      </div >
    );

  }
}


export default observer(ReporteAtencionesDetalladoNacional);
