import React from "react";
import "../App.css";
import moment from "moment";
import "moment/locale/es";
import { observer } from "mobx-react";
import authAxios from "../utils/authAxios";
import { LocalData } from "../utils/localData";
import {
  Button,
  Row,
  Col,
  DatePicker,
  TimePicker,
  Select,
  Input,
  Pagination,
} from "antd";
const { RangePicker } = DatePicker;
interface MyState {
  data: any;
  loading: boolean;
  valueSearch: string;
  dataSearch: any[];
  filtro_data: any;
}
class ReporteComponent extends React.Component<any, MyState> {
  static defaultProps = {
    showHeaders: true,
  };
  //titulo_reporte = Reporte desempeño sucursal y funcionarios";
  //columnas = [
  //   {
  //     title: 'Sucursal',
  //     key: 'sucursal',
  //   },
  //   {
  //     title: 'Tipo de atención',
  //     key: 'tipo_atencion',
  //   },
  //   {
  //     title: 'Funcionarios',
  //     key: 'funcionario',
  //   },
  //   {
  //     title: 'Atendidos',
  //     key: 'atendidos',
  //   },
  //   {
  //     title: 'Prom atención diarios',
  //     key: 'prom_atencion',
  //   },
  // ];
  //filtros = [
  //   {
  //     text: "Sucursales",
  //     type: "select",
  //   },
  //   {
  //     text: "Fecha de inicio",
  //     type: "datepicker",
  //   },
  //   {
  //     text: "Fecha de término",
  //     type: "datepicker",
  //   },
  //   {
  //     text: "Tipo de Atención",
  //     type: "select",
  //   },
  //   {
  //     text: "Funcionarios",
  //     type: "select",
  //   }
  // ]
  grid_max = 24;
  ancho_columna;
  ancho_total;
  filters = { selectedTenant: LocalData.get("selectedTenant")._id };
  pagination = {
    page: 1,
    perPage: 10,
  };
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      loading: false,
      valueSearch: "",
      dataSearch: [],
      filtro_data: {},
      total: 0,
      fire: 0,
    };
    this.grid_max = 24;
  }

  async componentDidMount() {
    this.setState({ loading: true });
    this.initFiltros();
    //this.makeRemoteRequest();
  }

  initFiltros = async () => {
    var state = { filtro_data: {} };
    await Promise.all(
      this.props.filtros.map(async (filtro) => {
        if (filtro.default) {
          this.filters[filtro.itemKey] = filtro.default;
        }
        if (filtro.itemData) {
          state.filtro_data[filtro.itemKey] = filtro.itemData;
          this.filters[filtro.itemKey] = filtro.default ? filtro.default : [];
        }
        if (filtro.itemUrl) {
          var filter_data = await authAxios.get("/reportes/" + filtro.itemUrl);
          //console.log('filter_data', filter_data);
          state.filtro_data[filtro.itemKey] = filter_data.data.rows;
          this.filters[filtro.itemKey] = filtro.default ? filtro.default : [];
        }
        //console.log('%c⧭ filtro.selectHandle', 'color: #e57373', filtro.selectHandle);
        if (filtro.selectHandle) {
          const { filters, filtro_data } = filtro.selectHandle(
            this.filters,
            state.filtro_data
          );
          this.filters = filters;
          state.filtro_data = filtro_data;
        }
      })
    );
    //console.log('%c⧭ initFiltros', 'color: #73998c', state);
    this.setState(state);
  };

  makeRemoteRequest = async () => {
    console.log("pagination", this.pagination);
    console.log("filters", this.filters);
    const data = await authAxios.get(
      this.props.url_reporte +
        `?page=${this.pagination.page}&perPage=${this.pagination.perPage}`,
      {
        params: {
          filter: this.filters,
        },
      }
    );

    this.setState({
      data: data.data.rows,
      total: data.data.total,
    });
  };

  handleChange(value, filtro) {
    console.log("handleChange", filtro);
    console.log("selected", value);
    this.filters[filtro.itemKey] = value;
    if (this.filters[filtro.itemKey] === null)
      delete this.filters[filtro.itemKey];
    if (filtro.selectHandle) {
      const { filters, filtro_data } = filtro.selectHandle(
        this.filters,
        this.state.filtro_data
      );
      this.filters = filters;
      console.log("%c⧭ filtersfiltersfilters", "color: #ffa280", filters);
      this.setState({ filtro_data: filtro_data });
    }
    //this.makeRemoteRequest();
  }

  buscar() {
    this.makeRemoteRequest();
  }

  changePageSize = (p) => {
    this.pagination.perPage = p;
    this.forceUpdate();
    this.makeRemoteRequest();
    console.log("change perPage", p);
  };
  changePage = (p) => {
    this.pagination.page = p;
    this.forceUpdate();
    this.makeRemoteRequest();
    console.log("change page", p);
  };

  render() {
    const Option = Select.Option;
    const { loading, data, filtro_data, total } = this.state;
    const { page, perPage } = this.pagination;
    const gerenteNacional = LocalData.get("gerenteNacional");
    const selectedTenant = LocalData.get("selectedTenant");
    console.log("state", this.state);
    let used_span = 0;
    let span_count = 0;
    this.props.columnas.forEach((col) => {
      if (col.span) {
        span_count++;
        used_span += col.span;
      }
    });
    console.log("%c⧭ used_span", "color: #920789", used_span);
    console.log("%c⧭ span_count", "color: #920789", span_count);
    this.ancho_columna = Math.floor(
      (this.grid_max - used_span) / (this.props.columnas.length - span_count)
    );
    if (!this.ancho_columna) this.ancho_columna = 0;
    console.log(
      "%c⧭ cantidad de col auto",
      "color: #8a07d6",
      this.props.columnas.length - span_count
    );
    console.log("%c⧭ ancho_columna", "color: #8a07d6", this.ancho_columna);
    this.ancho_total = this.props.total_span
      ? this.props.total_span
      : this.ancho_columna * (this.props.columnas.length - span_count) +
        used_span;
    console.log(
      "%c⧭ ancho_columna ancho_columna",
      "color: #1d5673",
      this.ancho_columna
    );
    console.log(
      "%c⧭ ancho_total ancho_total",
      "color: #1d5673",
      this.ancho_total
    );

    let format = "";
    let filtros = (
      <Row justify="center" type="flex">
        <Col span={this.ancho_total}>
          {!gerenteNacional ? (
            <div>
              <h1>{selectedTenant.name}</h1>
            </div>
          ) : null}
          <Row justify="start" type="flex">
            <div className="filters-card">
              {this.props.filtros.map((filtro) => {
                const options = filtro_data[filtro.itemKey]
                  ? filtro_data[filtro.itemKey]
                  : [];
                switch (filtro.type) {
                  case "timerangepicker":
                    var format = filtro.format ? filtro.format : "DD/MM/YYYY";
                    return (
                      <div>
                        <div
                          style={{
                            width: "100%",
                            paddingBottom: 10,
                            display: "inline-flex",
                            placeContent: "space-between",
                          }}
                        >
                          <div style={{ padding: 5, marginRight: 10 }}>
                            {filtro.text}
                          </div>
                          <div style={{ minWidth: 400, maxWidth: 400 }}>
                            <div style={{ width: "100%", textAlign: "right" }}>
                              {/* <TimePicker.RangePicker /> No existe en la version de antd */}
                              <TimePicker.RangePicker
                                defaultValue={filtro.default}
                                style={{ width: "100%" }}
                                onChange={(e) => this.handleChange(e, filtro)}
                                format={format}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  case "rangepicker":
                    var format = filtro.format ? filtro.format : "DD/MM/YYYY";
                    return (
                      <div>
                        <div
                          style={{
                            width: "100%",
                            paddingBottom: 10,
                            display: "inline-flex",
                            placeContent: "space-between",
                          }}
                        >
                          <div style={{ padding: 5, marginRight: 10 }}>
                            {filtro.text}
                          </div>
                          <div style={{ minWidth: 400, maxWidth: 400 }}>
                            <div style={{ width: "100%", textAlign: "right" }}>
                              <RangePicker
                                style={{ width: "100%" }}
                                onChange={(e) => this.handleChange(e, filtro)}
                                format={format}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  case "datepicker":
                    var format = filtro.format ? filtro.format : "DD/MM/YYYY";
                    return (
                      <div>
                        <div
                          style={{
                            width: "100%",
                            paddingBottom: 10,
                            display: "inline-flex",
                            placeContent: "space-between",
                          }}
                        >
                          <div style={{ padding: 5, marginRight: 10 }}>
                            {filtro.text}
                          </div>
                          <div style={{ minWidth: 400 }}>
                            <div style={{ width: "100%", textAlign: "right" }}>
                              <DatePicker
                                style={{ width: "100%" }}
                                onChange={(e) => this.handleChange(e, filtro)}
                                format={format}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  case "select":
                    return (
                      <div>
                        <div
                          style={{
                            width: "100%",
                            paddingBottom: 10,
                            display: "inline-flex",
                            placeContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              padding: 5,
                              marginRight: 10,
                            }}
                          >
                            {filtro.text}
                          </div>
                          <div style={{ minWidth: 400, maxWidth: 400 }}>
                            <div style={{ width: "100%", textAlign: "right" }}>
                              <Select
                                allowClear={true}
                                mode="multiple"
                                style={{ width: "100%" }}
                                placeholder={
                                  filtro.placeholder
                                    ? filtro.placeholder
                                    : "Todos"
                                }
                                onChange={(e) => this.handleChange(e, filtro)}
                              >
                                {console.log(
                                  "🚀 ~ file: ReporteComponent.js ~ line 275 ~ ReporteComponent ~ render ~ options",
                                  options
                                )}
                                {options.map((o) => (
                                  <Option key={o._id}>
                                    {filtro.itemText(o)}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  case "singleselect":
                    return (
                      <div>
                        <div
                          style={{
                            width: "100%",
                            paddingBottom: 10,
                            display: "inline-flex",
                            placeContent: "space-between",
                          }}
                        >
                          <div style={{ padding: 5, marginRight: 10 }}>
                            {filtro.text}
                          </div>
                          <div style={{ minWidth: 400, maxWidth: 400 }}>
                            <div style={{ width: "100%", textAlign: "right" }}>
                              <Select
                                style={{ width: "100%" }}
                                allowClear
                                placeholder={
                                  filtro.placeholder
                                    ? filtro.placeholder
                                    : "Seleccionar"
                                }
                                onChange={(e) => this.handleChange(e, filtro)}
                                defaultValue={filtro.default}
                              >
                                {options.map((o) => (
                                  <Option key={o._id} value={o._id}>
                                    {filtro.itemText(o)}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  default:
                    console.log("filtro inválido", filtro.type);
                    return <div>-</div>;
                }
              })}
              <div
                style={{
                  display: "inline-block",
                  width: "100%",
                  textAlign: "right",
                }}
              >
                <Button
                  onClick={() => this.buscar()}
                  size="small"
                  style={{
                    minWidth: 72,
                    borderRadius: 0,
                    fontSize: 11,
                    fontWeight: 600,
                    backgroundColor: "#20273D",
                    border: "2px solid #dfdfdf",
                    color: "white",
                  }}
                >
                  Buscar
                </Button>
              </div>
            </div>
          </Row>
        </Col>
      </Row>
    );

    let data_rows = this.props.dataFunc
      ? this.props.dataFunc(data, this.filters)
      : data;
    console.log("%c⧭ data_rows", "color: #00e600", data_rows);

    let headers = this.props.showHeaders ? (
      <Row className="ctable headers" justify="center" type="flex">
        {this.props.columnas.map((column) => {
          return (
            <Col
              span={column.span ? column.span : this.ancho_columna}
              style={{ overflow: "hidden" }}
            >
              {column.title}
            </Col>
          );
        })}
      </Row>
    ) : null;
    const rowFunc = (row) => (
      <Row
        className={"ctable " + (row.header ? "headers" : "row-cell")}
        justify="center"
        type="flex"
      >
        {this.props.columnas.map((column) => {
          return (
            <Col
              span={column.span ? column.span : this.ancho_columna}
              style={{ whiteSpace: "nowrap", overflow: "hidden" }}
            >
              {column.columnaText
                ? column.columnaText(row)
                : column.key in row
                  ? row[column.key]
                  : ""}
            </Col>
          );
        })}
      </Row>
    );
    const totalFunc = (row) => {
      let inicio = false;
      return (
        <Row className="ctable" justify="center" type="flex">
          {this.props.columnas.map((column, i) => {
            let className = row.total_azul ? "total_azul" : "total";
            let col_span = column.span ? column.span : this.ancho_columna;
            if (row.total_span && column.key in row.total_span) {
              col_span = row.total_span[column.key];
            }
            if (!inicio && column.key in row) {
              inicio = true;
              className += " inicio";
            } else if (i == this.props.columnas.length - 1) {
              className += " final";
            }
            if (inicio) {
              return col_span ? (
                <Col span={col_span} className={className}>
                  {column.columnaText
                    ? column.columnaText(row)
                    : column.key in row
                      ? row[column.key]
                      : ""}
                </Col>
              ) : null;
            } else {
              return col_span ? (
                <Col span={col_span} className="cell">
                  &nbsp;
                </Col>
              ) : null;
            }
          })}
        </Row>
      );
    };

    let rows =
      data_rows && data_rows.length > 0 ? (
        data_rows.map((row) => {
          if (row.total || row.total_azul) {
            return totalFunc(row);
          } else {
            return rowFunc(row);
          }
        })
      ) : (
        <div />
      );
    let body = () =>
      total > 0 ? (
        <div>
          <div style={{ padding: 10 }}>
            <Row justify="center" type="flex">
              <Pagination
                size="small"
                onShowSizeChange={(c, p) => this.changePageSize(p)}
                current={page}
                onChange={(p) => this.changePage(p)}
                pageSize={perPage}
                total={total}
                showTotal={(total, range) =>
                  `Mostrando ${range[0]}-${range[1]} de ${total}`
                }
                showSizeChanger
                showQuickJumper
              />
            </Row>
          </div>
          <div>
            <Row className="ctable title" justify="center" type="flex">
              <Col span={this.ancho_total}>{this.props.titulo_reporte}</Col>
            </Row>
            {headers}
            {rows}
          </div>
          <div style={{ padding: 10 }}>
            <Row justify="center" type="flex">
              <Pagination
                size="small"
                onShowSizeChange={(c, p) => this.changePageSize(p)}
                current={page}
                onChange={(p) => this.changePage(p)}
                pageSize={perPage}
                total={total}
                showTotal={(total, range) =>
                  `Mostrando ${range[0]}-${range[1]} de ${total}`
                }
                showSizeChanger
                showQuickJumper
              />
            </Row>
          </div>{" "}
        </div>
      ) : (
        <div />
      );

    return (
      <div>
        <div>{filtros}</div>
        <br />
        {body()}
        <br />
        <br />
      </div>
    );
  }
}

export default ReporteComponent;
