import authAxios from '../utils/authAxios';
import { AuthToken } from '../utils/authToken';
import { LocalData } from '../utils/localData';

export default class AuthService {

  static async signinWithEmailAndPassword(email, password) {

    const response = await authAxios.post('/auth/sign-in', {
      email,
      password
    });

    const token = response.data;
    AuthToken.set(token, true);
    console.log('%c⧭ signinWithEmailAndPassword', 'color: #99adcc', token);
    await this.fetchMe();

    return response.data;
  }

  static async fetchMe() {
    try {
      const response = await authAxios.get('/auth/me');
      const user = response.data;
      console.log('%c⧭ user', 'color: #99adcc', user);

      let tieneRol;
      let roles = ['gerenteNacional', 'jefeSucursal'];

      user.tenants.forEach((tenant) => {
        //console.log('%c⧭ tenant roles', 'color: #d0bfff', tenant.roles);
        tieneRol = tenant.roles.some((role) => roles.includes(role));
      });
      //console.log('%c⧭ user', 'color: #41bbc4', user);
      console.log('%c⧭ tieneRol', 'color: #41bbc4', tieneRol);

      if (!tieneRol) {
        LocalData.clear();
        throw 'Usuario sin privilegios';
      }

      LocalData.set('current_user', user);
      return user;

    } catch (error) {
      if (error.response.status === 401) {
        AuthToken.set(null, true);
      }
    }

  }

  static signout() {
    LocalData.clear();
  }

  static async updateProfile(data) {
    const body = {
      data,
    };

    const response = await authAxios.put(
      '/auth/profile',
      body,
    );

    return response.data;
  }

}