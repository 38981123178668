import React, { Component, useState } from 'react';
import { Router, Route, Redirect, Switch } from "react-router-dom";
import Login from './pages/Login';
import Reportes from './pages/Reportes';
import history from './history';
import { message, ConfigProvider, notification } from 'antd';
import { getServiceStitch } from './utils/hooks';
import es_ES from 'antd/lib/locale-provider/es_ES';
import ReporteAtencionesResumenNacional from './pages/ReporteAtenciones/ReporteAtencionesResumenNacional';
import ReporteAtencionesDetalladoNacional from './pages/ReporteAtenciones/ReporteAtencionesDetalladoNacional';
import ReporteAtencionesSucursal from './pages/ReporteAtenciones/ReporteAtencionesSucursal';
import ReporteDesempenoNacional from './pages/ReporteDesempeno/ReporteDesempenoNacional';
import ReporteDesempenoSucursal from './pages/ReporteDesempeno/ReporteDesempenoSucursal';
import ReporteClienteDetalladoNacional from './pages/ReporteCliente/ReporteClienteDetalladoNacional';
import ReporteClienteResumenNacional from './pages/ReporteCliente/ReporteClienteResumenNacional';
import ReporteClienteSucursal from './pages/ReporteCliente/ReporteClienteSucursal';
import ReporteHorarioPunta from './pages/ReporteHorarioPunta/ReporteHorarioPunta';
import { AuthToken } from './utils/authToken';
import AuthService from './services/authService';
import { LocalData } from './utils/localData';
import ReporteHorarioPuntaSucursal from './pages/ReporteHorarioPunta/ReporteHorarioPuntaSucursal';

const GuardedRoute = ({ component: Component, auth, roles = null, redirectTo = null, rerender = null, ...rest }) => (
  <Route {...rest} render={(props) => (
    auth === true
      ? <Component rerender={rerender} stitchService={getServiceStitch} {...props} />
      : redirectTo ? <Redirect to={redirectTo} /> : null
  )} />
)

class App extends Component {

  async componentDidMount() {
    this.setState({ loading: true });

    if (AuthToken.get()) {
      AuthService.fetchMe();
    }
  }

  // login() {
  //   AuthService.signinWithEmailAndPassword('kroegercarl@gmail.com', '1234');
  //   console.log("login, token:", AuthToken.get())
  // }

  logout() {
    AuthToken.set(null, true);
    console.log("logout");
  }

  rerender() {
    const auth = !!(AuthToken.get());
    console.log('%c⧭ rerender ¿isAutheticated?', 'color: #7f7700', auth);
    this.forceUpdate();
  }

  checkRole = (roles = []) => {
    const user = LocalData.get('current_user');
    //console.log('%c⧭ checkRole user tenants', 'color: #00736b', user.tenants);
    let tieneRol = false;
    if (!user) return false;
    user.tenants.forEach((tenant) => {
      //console.log('%c⧭ tenant roles', 'color: #d0bfff', tenant.roles);
      tenant.roles.forEach((role) => {
        if (roles.includes(role)) tieneRol = true;
      });
    });

    return tieneRol;
  }

  render() {
    const isAutheticated = !!(AuthToken.get());

    return (
      <ConfigProvider locale={es_ES}>
        <Router history={history}>
          <Switch>
            <GuardedRoute exact path='/' component={Login} auth={!isAutheticated} redirectTo={'/reportes'} rerender={this.rerender.bind(this)} />
            <GuardedRoute exact redirectTo="/" path='/reportes' component={Reportes} auth={isAutheticated && this.checkRole(['gerenteNacional', 'jefeSucursal'])} rerender={this.rerender.bind(this)} />
            <GuardedRoute exact path='/reporte-atenciones-detallado-nacional' component={ReporteAtencionesDetalladoNacional} auth={isAutheticated && this.checkRole(['gerenteNacional'])} />
            <GuardedRoute exact path='/reporte-atenciones-resumen-nacional' component={ReporteAtencionesResumenNacional} auth={isAutheticated && this.checkRole(['gerenteNacional'])} />
            <GuardedRoute exact path='/reporte-atenciones-sucursal' component={ReporteAtencionesSucursal} auth={isAutheticated && this.checkRole(['jefeSucursal'])} />
            <GuardedRoute exact path='/reporte-desempeno-nacional' component={ReporteDesempenoNacional} auth={isAutheticated && this.checkRole(['gerenteNacional'])} />
            <GuardedRoute exact path='/reporte-desempeno-sucursal' component={ReporteDesempenoSucursal} auth={isAutheticated && this.checkRole(['jefeSucursal'])} />
            <GuardedRoute exact path='/reporte-cliente-detallado-nacional' component={ReporteClienteDetalladoNacional} auth={isAutheticated && this.checkRole(['gerenteNacional'])} />
            <GuardedRoute exact path='/reporte-cliente-resumen-nacional' component={ReporteClienteResumenNacional} auth={isAutheticated && this.checkRole(['gerenteNacional'])} />
            <GuardedRoute exact path='/reporte-cliente-sucursal' component={ReporteClienteSucursal} auth={isAutheticated && this.checkRole(['jefeSucursal'])} />
            <GuardedRoute exact path='/reporte-horario-punta' component={ReporteHorarioPunta} auth={isAutheticated && this.checkRole(['gerenteNacional'])} />
            <GuardedRoute path='/reporte-horario-punta-sucursal' component={ReporteHorarioPuntaSucursal} auth={isAutheticated && this.checkRole(['jefeSucursal'])} />
          </Switch>
        </Router>
      </ConfigProvider>
    );
  }
}

export default App;
