import React from 'react';
import logo from '../../images/logo.png';
/*import { hot } from 'react-hot-loader'*/
import '../../App.css';
import moment from 'moment';
import 'moment/locale/es';
import history from '../../history';
import { observer } from 'mobx-react';
import authAxios from '../../utils/authAxios';
import { LocalData } from '../../utils/localData';
import {
  Button,
  Row,
  Col,
  Tooltip,
  DatePicker,
  Select,
  Drawer,
  TimePicker,
  Icon,
  Input,
  AutoComplete,
  Card,
  Skeleton,
  Avatar,
  Table,
  Pagination
} from 'antd';
import ReporteComponent from '../../components/ReporteComponent';


const { Option } = AutoComplete;
const { Meta } = Card;

interface MyState {
  data: any,
  loading: boolean,
  valueSearch: string,
  dataSearch: any[],
}
class ReporteAtencionesSucursal extends React.Component<any, MyState> {
  titulo_reporte = "Reporte online atenciones Banco Estado";
  url_reporte = '/reportes/' + 'atenciones-sucursal';
  columnas = [
    {
      title: 'Tipo atención',
      key: 'nombre',
      span: 12
    },
    {
      title: 'Atendiendo',
      key: 'atendiendo',
      span: 6
    },
    {
      title: 'En espera',
      key: 'en_espera',
      span: 6
    }
  ];
  filtros = [
    {
      text: "Tipo de Atención",
      placeholder: "Todas",
      type: "select",
      itemUrl: "tipoatencion-cliente?[filter][sucursal]=" + LocalData.get('selectedTenant')._id,//&[filter][funcionarios]=1",
      itemKey: "tipos_atencion",
      itemText: (item) => (item.nombre + ' (' + item.sucursal.name + ')')
    }
  ]
  constructor(props) {

    super(props);

    this.state = {
      data: null,
      loading: false,
      valueSearch: "",
      dataSearch: [],
    }
  }

  home = () => {
    const location = {
      pathname: '/reportes',
    }

    history.push(location);
  }

  render() {

    const dataFunc = (data, filters = {}) => {
      const nombre_sucursal = "Banco Estado Sucursal Concepción"
      if (!data) return [];
      let data_rows: any = [];
      let totales = {
        nombre: `TOTAL ${nombre_sucursal.toUpperCase()}`,
        total: true,
        atendiendo: 0,
        en_espera: 0
      }
      data.forEach((sucursal) => {
        totales.atendiendo += sucursal.atendiendo;
        totales.en_espera += sucursal.en_espera;
        data_rows.push(sucursal);
      });
      data_rows.push(totales);
      return data_rows;
    }

    return (
      <div className="App">
        <div className="Rooms-Home">
          <Row className="App-Header-Rooms">
            <Col lg={{ offset: 1, span: 11 }} xs={{ offset: 1, span: 11 }} >
              <Row className="logoHeader" type="flex" justify="start" align="middle">
                <label onClick={this.home} style={{ color: 'white' }}><Icon style={{ color: 'white' }} type="arrow-left" /> Volver Atrás</label>
              </Row>
            </Col>
            <Col lg={{ span: 11 }} xs={{ span: 11 }} >
              <Row className="logoHeader" type="flex" justify="end" align="middle">
                <a target="_BLANK" href="https://www.nomasfilas.cl"><img onClick={this.home} alt="LOGO" style={{ width: '15em', padding: 30 }} src={logo} /></a>
              </Row>
            </Col>
          </Row>
          <br />
          <br />
          <div className="titulo-reporte">Reporte Online de Atenciones</div>
          <br />
          <div className="subtitulo-reporte">Vista Jefe Sucursal</div>
          <Col lg={{ span: 17 }} md={{ span: 21 }} xs={{ span: 24 }} style={{ padding: 50, paddingLeft: "6vw" }}>
            <ReporteComponent dataFunc={dataFunc} url_reporte={this.url_reporte} titulo_reporte={this.titulo_reporte} columnas={this.columnas} filtros={this.filtros} />
          </Col>
        </div>
      </div >
    );

  }
}


export default observer(ReporteAtencionesSucursal);
