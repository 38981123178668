import React from "react";
import logo from "../../images/logo.png";
/*import { hot } from 'react-hot-loader'*/
import "../../App.css";
import moment from "moment";
import "moment/locale/es";
import history from "../../history";
import { observer } from "mobx-react";
import authAxios from "../../utils/authAxios";
import { LocalData } from "../../utils/localData";
import {
  Button,
  Row,
  Col,
  Tooltip,
  DatePicker,
  Select,
  Drawer,
  TimePicker,
  Icon,
  Input,
  AutoComplete,
  Card,
  Skeleton,
  Avatar,
  Table,
  Pagination,
} from "antd";
import ReporteComponent from "../../components/ReporteComponent";

const { Option } = AutoComplete;
const { Meta } = Card;

interface MyState {
  data: any;
  loading: boolean;
  valueSearch: string;
  dataSearch: any[];
}
class ReporteDesempenoSucursal extends React.Component<any, MyState> {
  titulo_reporte = "Reporte desempeño sucursal y funcionarios";
  url_reporte = "/reportes/" + "desempeno-sucursal";
  columnas = [
    {
      title: "Sucursal",
      key: "sucursal",
    },
    {
      title: "Tipo de atención",
      key: "tipo_atencion",
    },
    {
      title: "Funcionarios",
      key: "funcionario",
    },
    {
      title: "Atendidos",
      key: "atendidos",
      sumar: true,
    },
    {
      title: "Prom atención diarios",
      key: "prom_atencion_diarios",
      sumar: true,
    },
    {
      title: "Prom tiempo atención",
      key: "prom_tiempo_atencion",
      sumar: true,
    },
    {
      title: "Valorización promedio",
      key: "prom_valorizacion",
      sumar: true,
    },
    {
      title: "Promedio pausa",
      key: "prom_pausa",
      sumar: true,
    },
    {
      title: "Amonestaciones",
      key: "amonestaciones",
      sumar: true,
    },
  ];
  filtros = [
    {
      text: "Fecha de inicio",
      type: "datepicker",
      itemKey: "fecha_inicio",
    },
    {
      text: "Fecha de término",
      type: "datepicker",
      itemKey: "fecha_termino",
    },
    {
      text: "Tipo de Atención",
      type: "select",
      itemUrl:
        "tipoatencion-cliente?[filter][sucursal]=" +
        LocalData.get("selectedTenant")._id,
      itemKey: "tipos_atencion",
      itemText: (item) => item.nombre + " (" + item.sucursal.name + ")",
      selectHandle: (filters, filtro_data) => {
        return this.selectedFunc(filters, filtro_data);
      },
    },
    {
      text: "Funcionarios",
      type: "select",
      itemKey: "funcionarios",
      itemText: (item) => {
        if (item.fullName) return item.fullName;
        if (item.firstName && item.lastName)
          return item.firstName + " " + item.lastName;
        if (item.email) return item.email;
      },
    },
  ];

  selectedFunc(filters, filtro_data) {
    console.log("updateFilters", filtro_data);
    var items = filters.tipos_atencion ? filters.tipos_atencion : [];
    var funcionarios = [];
    if (filtro_data.tipos_atencion) {
      filtro_data.tipos_atencion.forEach((tipo_atencion) => {
        console.log(
          "🚀 ~ file: ReporteDesempenoSucursal.js ~ line 124 ~ ReporteDesempenoSucursal ~ filtro_data.tipos_atencion.forEach ~ tipo_atencion",
          tipo_atencion
        );
        console.log("tipo_atencion", tipo_atencion);
        if (tipo_atencion.funcionarios) {
          if (items.length > 0) {
            items.forEach((id_sel) => {
              if (tipo_atencion._id == id_sel) {
                var ids = new Set(funcionarios.map((f) => f._id));
                funcionarios = [
                  ...funcionarios,
                  ...tipo_atencion.funcionarios.filter((f) => !ids.has(f._id)),
                ];
              }
            });
          } else {
            var ids = new Set(funcionarios.map((f) => f._id));
            funcionarios = [
              ...funcionarios,
              ...tipo_atencion.funcionarios.filter((f) => !ids.has(f._id)),
            ];
          }
        }
      });
    }
    console.log("funcionarios", funcionarios);
    filtro_data.funcionarios = funcionarios;
    return { filters, filtro_data };
  }
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      loading: false,
      valueSearch: "",
      dataSearch: [],
    };
  }

  home = () => {
    const location = {
      pathname: "/reportes",
    };

    history.push(location);
  };

  render() {
    const Option = Select.Option;
    const { loading, data, dataSearch } = this.state;

    const dataFunc = (data, filters = {}) => {
      if (!data) return [];
      let data_rows: any = [];
      let sumar = this.columnas.filter((c) => c.sumar);
      data.forEach((sucursal) => {
        let row: any = {};
        let totales_s = Object.fromEntries(sumar.map((col) => [col.key, 0]));
        if (sucursal.tipos_atencion) {
          sucursal.tipos_atencion.forEach((atencion, i) => {
            row.sucursal = i == 0 ? sucursal.name : "";
            let totales_a = Object.fromEntries(
              sumar.map((col) => [col.key, 0])
            );
            if (atencion.funcionarios.length > 0) {
              atencion.funcionarios.forEach((funcionario, j) => {
                row.tipo_atencion = j == 0 ? atencion.nombre : "";
                row.funcionario = funcionario.fullName;
                sumar.forEach((col) => {
                  row[col.key] = funcionario[col.key];
                  totales_a[col.key] += funcionario[col.key];
                });
                data_rows.push(row);
                row = {};
              });
              row.tipo_atencion = "TOTAL " + atencion.nombre.toUpperCase();
              sumar.forEach((col) => {
                row[col.key] = totales_a[col.key];
                totales_s[col.key] += totales_a[col.key];
              });
              row.total = true;
              row.total_span = { tipo_atencion: 4, funcionario: 0 };
              data_rows.push(row);
              row = {};
            }
          });
          row.sucursal = "TOTAL " + sucursal.name.toUpperCase();
          sumar.forEach((col) => {
            row[col.key] = totales_s[col.key];
          });
          row.total = true;
          row.total_span = { sucursal: 6, tipo_atencion: 0, funcionario: 0 };
          data_rows.push(row);
          row = {};
        }
      });
      return data_rows;
    };

    return (
      <div className="App">
        <div className="Rooms-Home">
          <Row className="App-Header-Rooms">
            <Col lg={{ offset: 1, span: 11 }} xs={{ offset: 1, span: 11 }}>
              <Row
                className="logoHeader"
                type="flex"
                justify="start"
                align="middle"
              >
                <label onClick={this.home} style={{ color: "white" }}>
                  <Icon style={{ color: "white" }} type="arrow-left" /> Volver
                  Atrás
                </label>
              </Row>
            </Col>
            <Col lg={{ span: 11 }} xs={{ span: 11 }}>
              <Row
                className="logoHeader"
                type="flex"
                justify="end"
                align="middle"
              >
                <a target="_BLANK" href="https://www.nomasfilas.cl">
                  <img
                    onClick={this.home}
                    alt="LOGO"
                    style={{ width: "15em", padding: 30 }}
                    src={logo}
                  />
                </a>
              </Row>
            </Col>
          </Row>
          <br />
          <br />
          <div className="titulo-reporte">
            Reporte Desempeño Sucursales y Funcionarios
          </div>
          <br />
          <div className="subtitulo-reporte">Vista Jefe Sucursal</div>
          <br />
          <div style={{ marginLeft: "-9vw", marginRight: "-9vw" }}>
            <ReporteComponent
              dataFunc={dataFunc}
              url_reporte={this.url_reporte}
              titulo_reporte={this.titulo_reporte}
              columnas={this.columnas}
              filtros={this.filtros}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default observer(ReporteDesempenoSucursal);
