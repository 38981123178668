import React from 'react';
import logo from '../../images/logo.png';
/*import { hot } from 'react-hot-loader'*/
import '../../App.css';
import moment from 'moment';
import 'moment/locale/es';
import history from '../../history';
import { observer } from 'mobx-react';
import authAxios from '../../utils/authAxios';
import {
  Button,
  Row,
  Col,
  Tooltip,
  DatePicker,
  Select,
  Drawer,
  TimePicker,
  Icon,
  Input,
  AutoComplete,
  Card,
  Skeleton,
  Avatar,
  Table,
  Pagination
} from 'antd';
import ReporteComponent from '../../components/ReporteComponent';


const { Option } = AutoComplete;
const { Meta } = Card;

interface MyState {
  data: any,
  loading: boolean,
  columnas: any
}
class ReporteClienteDetalladoNacional extends React.Component<any, MyState> {
  titulo_reporte = "Reporte preferencias y comportamientos cliente";
  url_reporte = '/reportes/' + 'cliente-detallado-nacional';
  columnas = [
    {
      title: 'Sucursal',
      key: 'sucursal',
    },
    {
      title: 'Tipo de atención',
      key: 'tipo_atencion',
    },
    {
      title: '*',
      key: 'valorizacion_1',
      span: 1,
      sumar: true
    },
    {
      title: '**',
      key: 'valorizacion_2',
      span: 1,
      sumar: true
    },
    {
      title: '***',
      key: 'valorizacion_3',
      span: 1,
      sumar: true
    },
    {
      title: '****',
      key: 'valorizacion_4',
      span: 1,
      sumar: true
    },
    {
      title: '*****',
      key: 'valorizacion_5',
      span: 1,
      sumar: true
    },
    {
      title: 'Valoraciones',
      key: 'total_valorizacion',
      sumar: true
    },
    {
      title: 'Cancelados',
      key: 'cancelados',
      sumar: true
    },
    // {
    //   title: 'TOTAL',
    //   key: 'total_cancelados',
    //   sumar:true
    // },
    {
      title: 'Visualizaciones',
      key: 'visualizacion',
      sumar: true
    },
    {
      title: 'Atenciones',
      key: 'atenciones',
      sumar: true
    },
    // {
    //   title: 'TOTAL',
    //   key: 'total_visualizacion',
    //   sumar:true
    // },
  ];
  filtros = [
    {
      text: "Sucursales",
      placeholder: "Todas",
      type: "select",
      itemUrl: "sucursales-cliente?[filter][tipos_atencion]=1",//&[filter][funcionarios]=1",
      itemKey: "sucursales",
      itemText: (item) => (item.name),
      selectHandle: (filters, filtro_data) => {
        var items = filters.sucursales ? filters.sucursales : [];
        var tipos_atencion = [];
        if (filtro_data.sucursales) {
          filtro_data.sucursales.forEach(sucursal => {
            console.log("sucursal", sucursal);
            if (items.length > 0) {
              items.forEach(id_sel => {
                if (sucursal._id == id_sel) tipos_atencion = [...tipos_atencion, ...sucursal.tipos_atencion];
              });
            } else {
              tipos_atencion = [...tipos_atencion, ...sucursal.tipos_atencion];
            }
          })
        }
        console.log("tipos_atencion", tipos_atencion);
        filtro_data.tipos_atencion = tipos_atencion;
        return { filters, filtro_data };
      }
    },
    {
      text: "Fecha de inicio",
      type: "datepicker",
      itemKey: "fecha_inicio",
    },
    {
      text: "Fecha de término",
      type: "datepicker",
      itemKey: "fecha_termino",
    },
    {
      text: "Tipo de Atención",
      type: "select",
      itemKey: "tipos_atencion",
      itemText: (item) => (item.nombre + ' (' + item.tenant.name + ')'),
    },
    {
      text: "Tipo de Reporte",
      type: "select",
      itemKey: "tipos_reporte",
      itemData: [
        { _id: "valorizacion", nombre: "Valorización" },
        { _id: "cancelados", nombre: "Cancelados" },
        { _id: "visualizacion", nombre: "Visualizaciones" },
        { _id: "atenciones", nombre: "Atenciones" }
      ],
      itemText: (item) => (item.nombre)
    }
  ]
  constructor(props) {

    super(props);

    this.state = {
      data: null,
      loading: false,
      columnas: this.columnas,
      tipos_reporte: this.reportes
    }
  }

  home = () => {
    const location = {
      pathname: '/reportes',
    }

    history.push(location);
  }

  dibujarValorizacion = (num: Number) => {
    let str = "";
    for (let step = 0; step < num; step++) {
      str += "*";
    }
    return str;
  }
  reportes = [
    "valorizacion",
    "cancelados",
    "visualizacion",
    "atenciones"
  ];
  reportes_anterior = (array_to_check: string[]) => array_to_check.length ? this.state.tipos_reporte.reduce(
    (accumulator, current) => accumulator && array_to_check.includes(current),
    true
  ) : true;
  reportes_actual = (array_to_check: string[]) => (array_to_check && array_to_check.length) ? this.reportes.filter(current => array_to_check.includes(current)) : this.reportes;
  render() {
    const Option = Select.Option;
    const { loading, data, tipos_reporte } = this.state;
    let columnas = this.state.columnas;
    const valorizaciones = ["*", "**", "***", "****", "*****"];

    const dataFunc = (data, filters = {}) => {
      if (!data || data.length == 0) return [];
      let data_rows: any = [];
      let row: any = {}
      let new_tipos = this.reportes_actual(filters.tipos_reporte);
      console.log('%c⧭ new_tipos', 'color: #731d1d', new_tipos);
      const if_tipos_reporte = (!filters.tipos_reporte || filters.tipos_reporte.length == 0);
      const columnas_finales = this.columnas.filter((col) => {
        if (col.key.includes("valorizacion") && !if_tipos_reporte && !filters.tipos_reporte.includes("valorizacion")) return false;
        if (col.key.includes("cancelados") && !if_tipos_reporte && !filters.tipos_reporte.includes("cancelados")) return false;
        if (col.key.includes("visualizacion") && !if_tipos_reporte && !filters.tipos_reporte.includes("visualizacion")) return false;
        if (col.key.includes("atenciones") && !if_tipos_reporte && !filters.tipos_reporte.includes("atenciones")) return false;
        return true;
      })
      if (columnas_finales.length != columnas.length || !this.reportes_anterior(new_tipos)) {
        this.setState({ columnas: columnas_finales, tipos_reporte: new_tipos });
      }
      let used_span = 0;
      let span_count = 0;
      columnas.forEach((col) => {
        row[col.key] = col.title;
        if (col.span) {
          span_count++;
          used_span += col.span;
        }
      })
      let ancho_columna = Math.floor((24 - used_span) / (columnas.length - span_count));
      row.header = true;
      data_rows.push(row);
      row = {};
      let sumar = columnas.filter(c => c.sumar);
      let total_atenciones = 0;
      let total_final = Object.fromEntries(sumar.map(col => [col.key, 0]));
      data.forEach((sucursal) => {
        let total_s = Object.fromEntries(sumar.map(col => [col.key, 0]));
        sucursal.tipos_atencion.forEach((tipo_atencion, i) => {
          row.sucursal = i == 0 ? sucursal.name : '';
          // row.tipo_reporte = val;
          row.total_valorizacion = 0;
          row.tipo_atencion = tipo_atencion.nombre;
          if (if_tipos_reporte || filters.tipos_reporte.includes("valorizacion")) {
            for (let n = 1; n < 6; n++) {
              row['valorizacion_' + (n)] = 0;
              tipo_atencion.valorizacion.forEach((valorizacion) => {
                if (valorizacion._id && valorizacion._id == (n)) {
                  row['valorizacion_' + (n)] = valorizacion.total;//val._id?this.dibujarValorizacion(val._id):'Sin valorización';
                  row.total_valorizacion += valorizacion.total;
                  // total_s += valorizacion.total;
                }
              });
            }
          }
          if (if_tipos_reporte || filters.tipos_reporte.includes("valorizacion")) {

          }
          row.cancelados = tipo_atencion.cancelados;
          row.visualizacion = tipo_atencion.visualizacion;
          row.atenciones = tipo_atencion.atenciones;
          sumar.forEach(col => {
            total_s[col.key] += row[col.key];
          })
          data_rows.push(row);
          row = {}
        });
        total_s.sucursal = 'TOTAL ' + sucursal.name.toUpperCase();
        total_s.total_span = { sucursal: ancho_columna * 2, tipo_atencion: 0 };
        total_s.total = true;
        sumar.forEach(col => {
          total_final[col.key] += total_s[col.key];
        })
        data_rows.push(total_s);
      });
      total_final.sucursal = 'TOTAL';
      total_final.total_span = { sucursal: ancho_columna * 2, tipo_atencion: 0 };
      total_final.total = true;
      total_final.total_azul = true;
      data_rows.push(total_final);
      return data_rows;
    }

    return (
      <div className="App">
        <div className="Rooms-Home">
          <Row className="App-Header-Rooms">
            <Col lg={{ offset: 1, span: 11 }} xs={{ offset: 1, span: 11 }} >
              <Row className="logoHeader" type="flex" justify="start" align="middle">
                <label onClick={this.home} style={{ color: 'white' }}><Icon style={{ color: 'white' }} type="arrow-left" /> Volver Atrás</label>
              </Row>
            </Col>
            <Col lg={{ span: 11 }} xs={{ span: 11 }} >
              <Row className="logoHeader" type="flex" justify="end" align="middle">
                <a target="_BLANK" href="https://www.nomasfilas.cl"><img onClick={this.home} alt="LOGO" style={{ width: '15em', padding: 30 }} src={logo} /></a>
              </Row>
            </Col>
          </Row>
          <br />
          <br />
          <div className="titulo-reporte">Reporte Preferencias y Comportamientos del Cliente</div>
          <br />
          <div className="subtitulo-reporte">Vista Gerente Nacional Detallada</div>
          <br />
          <br />
          <Col style={{ marginLeft: !tipos_reporte ? "-2vw" : "3vw", marginRight: !tipos_reporte ? "-2vw" : "3vw" }}>
            <ReporteComponent showHeaders={false} dataFunc={dataFunc} url_reporte={this.url_reporte} titulo_reporte={this.titulo_reporte} columnas={columnas} filtros={this.filtros} />
          </Col>
        </div>
      </div >
    );

  }
}


export default observer(ReporteClienteDetalladoNacional);
