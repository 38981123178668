import { AuthToken } from '../utils/authToken';
export class LocalData {
  static set(key: string, content: any) {
    localStorage.setItem(key, JSON.stringify(content));
  }
  static get(key: string) {
    var local = localStorage.getItem(key);
    var item = (local != 'undefined' && local != null) ? JSON.parse(local) : null;
    //console.log('%c⧭ item', 'color: #f27999', item);
    return item;
  }
  static clear() {
    localStorage.clear();
    // var keysToClear = [
    //   "current_user",
    //   "jwt",
    //   "gerenteNacional",
    //   "selectedTenant"
    // ]
    // keysToClear.forEach(key=> localStorage.setItem(key, null));
    // AuthToken.set(null, true);
  }
}
