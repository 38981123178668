import React, { Component } from 'react';
import logo from '../images/logo.png';
/*import { hot } from 'react-hot-loader'*/
import '../App.css';
import moment from 'moment';
import 'moment/locale/es';
import history from '../history';
import { observer } from 'mobx-react';
import authAxios from '../utils/authAxios';
import { getDistance } from 'geolib';
import _ from 'lodash';
import { getLinkString } from '../utils/hooks';
import { AuthToken } from '../utils/authToken';

import {
  Button,
  Row,
  Col,
  Input,
  Form,
  notification
} from 'antd';

import AuthService from '../services/authService';

class Home extends Component {

  constructor(props) {
    super(props);
    this.locationWhere = null;

    this.state = {
      loading: false
    }
  }

  async componentDidMount() {
  }

  login($event) {
    console.log('%c⧭ login', 'color: #994d75', $event);
    this.setState({ loading: true });

    AuthToken.set(null, true);

    AuthService.signinWithEmailAndPassword($event.email, $event.password).then((data) => {
      console.log('%c⧭ signinWithEmailAndPassword', 'color: #00258c', data);
      this.rerender();
    }).catch((err) => {
      notification['error']({
        message: 'Credenciales Incorrectas',
        description:
          'Por favor, revise sus credenciales',
      });

      this.setState({ loading: false });
    });
  }

  goReportes = (item) => {
    const location = {
      pathname: '/reportes',
    }
    history.push(location);
  }

  home = () => {
    const location = {
      pathname: '/',
    }

    history.push(location);
  }

  rerender = () => {
    console.log('%c⧭ rerender', 'color: #00ff88', 'probando');
    this.props.rerender();
  }

  render() {
    const { loading } = this.state;

    return (
      <div className="App" style={{ backgroundColor: 'orangered' }}>
        <div className="Rooms-Home">
          <Row className="App-Header-Rooms">
            <Col lg={{ span: 23 }} xs={{ span: 23 }} >
              <Row className="logoHeader" type="flex" justify="end" align="middle">
                <a target="_BLANK" href="https://www.nomasfilas.cl"><img onClick={this.home} alt="LOGO" style={{ width: '15em', padding: 30 }} src={logo} /></a>
              </Row>
            </Col>
          </Row>
          <Row type="flex" justify="center" align="middle">
            <Form onFinish={this.login.bind(this)} style={{ backgroundColor: 'white', padding: window.innerWidth > 768 ? 60 : 20, margin: window.innerWidth > 768 ? 60 : 10, border: "1px solid gray", borderRadius: 5, width: window.innerWidth > 768 ? '30vw' : '100vw' }}>
              <h1 style={{ padding: 15, marginBottom: 20 }}>Iniciar Sesión</h1>
              <Form.Item name="email">
                <Input placeholder="Email" style={{ height: 38, borderRadius: 5, width: '99%' }} />
              </Form.Item>
              <Form.Item name="password">
                <Input.Password placeholder="Contraseña" style={{ height: 38, borderRadius: 5, width: '99%' }} />
              </Form.Item>
              <Form.Item style={{ paddingTop: 9 }}>
                <Button loading={loading} size={'large'} style={{ borderRadius: 20, fontWeight: 600, width: '100%' }} type="primary" htmlType="submit">Iniciar sesión</Button>
              </Form.Item>
            </Form>
          </Row>
        </div>
      </div>
    );

  }
}


export default observer(Home);

