import React from 'react';
import logo from '../../images/logo.png';
/*import { hot } from 'react-hot-loader'*/
import '../../App.css';
import moment from 'moment';
import 'moment/locale/es';
import history from '../../history';
import { observer } from 'mobx-react';
import authAxios from '../../utils/authAxios';
import {
  Button,
  Row,
  Col,
  Tooltip,
  DatePicker,
  Select,
  Drawer,
  TimePicker,
  Icon,
  Input,
  AutoComplete,
  Card,
  Skeleton,
  Avatar,
  Table,
  Pagination
} from 'antd';
import ReporteComponent from '../../components/ReporteComponent';


const { Option } = AutoComplete;
const { Meta } = Card;

interface MyState {
  data: any,
  loading: boolean,
  columnas: any
}
class ReporteHorarioPunta extends React.Component<any, MyState> {
  titulo_reporte = "Número atenciones";
  url_reporte = '/reportes/' + 'horario-punta';
  columnas = [
    {
      title: 'Sucursal',
      key: 'sucursal',
    },
    {
      title: 'Horario',
      key: 'horario',
    },
    {
      title: 'Lunes',
      key: 'lunes',
      sumar: true
    },
    {
      title: 'Martes',
      key: 'martes',
      sumar: true
    },
    {
      title: 'Miércoles',
      key: 'miercoles',
      sumar: true
    },
    {
      title: 'Jueves',
      key: 'jueves',
      sumar: true
    },
    {
      title: 'Viernes',
      key: 'viernes',
      sumar: true
    },
    {
      title: 'Sábado',
      key: 'sabado',
      sumar: true
    },
    {
      title: 'Domingo',
      key: 'domingo',
      sumar: true
    },
    {
      title: 'TOTAL',
      key: 'total_h'
    }
  ];
  filtros = [
    {
      text: "Sucursales",
      placeholder: "Todas",
      type: "select",
      itemUrl: "sucursales-cliente",
      itemKey: "sucursales",
      itemText: (item) => (item.name),
    },
    {
      text: "Tipo de reporte", //N° de atenciones, Tiempo prom de espera, Tiempo prom de atención
      type: "singleselect",
      itemKey: "tipos_reporte",
      itemData: [
        { _id: "atenciones", nombre: "N° de atenciones" },
        { _id: "tiempo_espera", nombre: "Tiempo prom de espera" },
        { _id: "tiempo_atencion", nombre: "Tiempo prom de atencion" },
      ],
      default: "atenciones",
      itemText: (item) => (item.nombre),
    },
    {
      text: "Rango por fecha",
      type: "rangepicker",
      itemKey: "rango_fecha",
    },
    {
      text: "Rango por hora",
      type: "timerangepicker",
      format: "HH:mm",
      itemKey: "rango_hora",
      default: [moment('08:00:00', 'HH:mm:ss'), moment('18:00:00', 'HH:mm:ss')],
      selectHandle: (filters, filtro_data) => {
        return this.selectedFunc(filters, filtro_data);
      },
    },
    {
      text: "Intervalo horarios",
      type: "singleselect",
      itemKey: "intervalo",
      itemData: [
        { _id: 1, nombre: "1 hora" },
        { _id: 2, nombre: "2 horas" },
        { _id: 3, nombre: "3 horas" },
        { _id: 4, nombre: "4 horas" },
        { _id: 5, nombre: "5 horas" },
        { _id: 6, nombre: "6 horas" },
        { _id: 7, nombre: "7 horas" },
        { _id: 8, nombre: "8 horas" },
        { _id: 9, nombre: "9 horas" },
        { _id: 10, nombre: "10 horas" },
        { _id: 11, nombre: "11 horas" },
        { _id: 24, nombre: "24 horas" }
      ],
      default: 1,
      itemText: (item) => (item.nombre),
      selectHandle: (filters, filtro_data) => {
        return this.selectedFunc(filters, filtro_data);
      },
    },
    {
      text: "Elegir días a mostrar",
      type: "select",
      itemKey: "dias_semana",
      itemData: [
        { _id: 1, nombre: "Lunes" },
        { _id: 2, nombre: "Martes" },
        { _id: 3, nombre: "Miércoles" },
        { _id: 4, nombre: "Jueves" },
        { _id: 5, nombre: "Viernes" },
        { _id: 6, nombre: "Sábado" },
        { _id: 7, nombre: "Domingo" }
      ],
      itemText: (item) => (item.nombre),
      selectHandle: (filters, filtro_data) => {

        return this.selectedFunc(filters, filtro_data);
      },
    }
  ];
  selectedFunc(filters, filtro_data) {
    console.log('%c⧭ selectedFunc', 'color: #731d6d', filters);
    let min, max;
    if (filters.rango_hora && filters.rango_hora.length == 2) {
      min = filters.rango_hora[0].format('HH:mm');
      max = filters.rango_hora[1].format('HH:mm');
    }
    let intervalo = filters.intervalo ? filters.intervalo : 1;
    let intervalos = this.getIntervalos(intervalo, min, max);
    console.log('%c⧭ ***** intervalos *****', 'color: #997326', intervalos);
    filters.intervalos = intervalos;
    return { filters, filtro_data };
  }
  constructor(props) {

    super(props);

    this.state = {
      data: null,
      loading: false,
      columnas: this.columnas,
    }
  }

  home = () => {
    const location = {
      pathname: '/reportes',
    }

    history.push(location);
  }

  // cRange = (a, b) => {
  //   return String(a).padStart(2, '0') + ':00 - ' + String(b - 1).padStart(2, '0') + ':59';
  // }
  intToTime = (hours, mins) => {
    return String(hours).padStart(2, '0') + ':' + String(mins).padStart(2, '0');
  }
  getIntervalos = (intervalo: Number = 1, min = '00:00', max = '23:59') => {
    console.log('%c⧭', 'color: #bfffc8', intervalo);
    console.log('%c⧭', 'color: #1d3f73', min);
    console.log('%c⧭', 'color: #cc0088', max);
    const [min_hours, min_min] = min.split(':', 2).map(s => parseInt(s));
    const [max_hours, max_min] = max.split(':', 2).map(s => parseInt(s));
    let intervalos = [];
    for (let i = min_hours; i <= max_hours; i += parseInt(intervalo)) {
      console.log('%c⧭ i intervalo', 'color: #305cbb', i, intervalo);
      let a = i;
      let b = i + parseInt(intervalo);
      if (b >= 24) b = 24;
      a = this.intToTime(a, min_min);
      let min_0: Number = (min_min == 0 ? b - 1 : b);
      let max_59: Number = ((min_min == 0 ? i : i + 1) == max_hours ? max_min : (min_min == 0 ? 59 : min_min - 1));
      b = this.intToTime(min_0, max_59);
      if (a >= min && b <= max && a != b) {
        intervalos.push(a + ' - ' + b);
      }
    }
    console.log('%c⧭ intervalos', 'color: #364cd9', intervalos);
    return intervalos;
  }
  render() {
    const Option = Select.Option;
    const { loading, data } = this.state;
    let columnas = this.state.columnas;
    let dia_semana = {
      "lunes": '1',
      "martes": '2',
      "miercoles": '3',
      "jueves": '4',
      "viernes": '5',
      "sabado": '6',
      "domingo": '7',
    }
    const dataFunc = (data, filters = {}) => {
      if (!data || data.length == 0) return [];
      let data_rows: any = [];
      let row: any = {}
      console.log('%c⧭ filters', 'color: #731d1d', filters);
      const columnas_finales = this.columnas.filter((col) => {
        if (filters.dias_semana.length > 0 && col.sumar && !filters.dias_semana.includes(dia_semana[col.key])) {
          console.log('%c⧭ dias_semana', 'color: #99614d', filters.dias_semana);
          console.log('%c⧭ col.key', 'color: #99614d', col.key);
          console.log('%c⧭ dia_semana[col.key]', 'color: #99614d', dia_semana[col.key]);
          return false;
        }
        return true;
      })
      if (columnas_finales.length != columnas.length) {
        this.setState({ columnas: columnas_finales });
      }
      let used_span = 0;
      let span_count = 0;
      columnas.forEach((col) => {
        row[col.key] = col.title;
        if (col.span) {
          span_count++;
          used_span += col.span;
        }
      })
      let ancho_columna = Math.floor((24 - used_span) / (columnas.length - span_count));
      row.header = true;
      data_rows.push(row);
      row = {};
      console.log('%c⧭ filters', 'color: #807160', filters);
      let min, max;
      if (filters.rango_hora && filters.rango_hora.length == 2) {
        min = filters.rango_hora[0].format('HH:mm');
        max = filters.rango_hora[1].format('HH:mm');
      }
      let intervalo = data ? data[0].intervalo : 1;
      let intervalos = this.getIntervalos(intervalo, min, max);
      let dias_semana = columnas.filter(c => c.sumar).map(c => c.key);
      data.forEach((sucursal) => {
        let suma_dia = Object.fromEntries(dias_semana.map(d => [d, 0]));
        suma_dia.total_h = 0;
        intervalos.forEach((intervalo, i) => {
          console.log('%c⧭ intervalo', 'color: #917399', intervalo);
          row = {};
          row.sucursal = (i == 0) ? sucursal.name : '';
          row.horario = intervalo;
          let suma_hora = 0;
          dias_semana.forEach((dia_sem) => {
            let dia = dia_semana[dia_sem];
            let hora = sucursal.horario ? sucursal.horario.find((hora) => (hora.hora == intervalo && hora.dia_semana == dia)) : null;
            row[dia_sem] = hora ? hora.total : 0;
            suma_dia[dia_sem] += row[dia_sem];
            suma_hora += row[dia_sem];
          })
          row.total_h = suma_hora;
          suma_dia.total_h += suma_hora;
          data_rows.push(row);
        });
        suma_dia.sucursal = 'TOTAL ' + sucursal.name.toUpperCase();
        suma_dia.total = true;
        suma_dia.total_span = { sucursal: ancho_columna * 2, horario: 0 }
        data_rows.push(suma_dia);
      });
      return data_rows;
    }
    let ccol = columnas.length - 3;
    let col_a = 0;
    let col_b = 24;
    let col_c = 0;
    if ([4].includes(ccol)) {
      col_a = 1; col_b = 22; col_c = 1;
    }
    if ([5, 3, 1].includes(ccol)) {
      col_a = 2; col_b = 20; col_c = 2;
    }
    let mcol = 0;
    if (ccol == 6) mcol = -85;
    if (ccol == 4) mcol = -30;

    return (
      <div className="App">
        <div className="Rooms-Home">
          <Row className="App-Header-Rooms">
            <Col lg={{ offset: 1, span: 11 }} xs={{ offset: 1, span: 11 }} >
              <Row className="logoHeader" type="flex" justify="start" align="middle">
                <label onClick={this.home} style={{ color: 'white' }}><Icon style={{ color: 'white' }} type="arrow-left" /> Volver Atrás</label>
              </Row>
            </Col>
            <Col lg={{ span: 11 }} xs={{ span: 11 }} >
              <Row className="logoHeader" type="flex" justify="end" align="middle">
                <a target="_BLANK" href="https://www.nomasfilas.cl"><img onClick={this.home} alt="LOGO" style={{ width: '15em', padding: 30 }} src={logo} /></a>
              </Row>
            </Col>
          </Row>
          <br />
          <br />
          <div className="titulo-reporte">Reporte Horarios Punta</div>
          {/* <br />
          <div className="subtitulo-reporte">-</div> */}
          <br />
          <Row style={{ marginLeft: mcol, marginRight: mcol }}>
            <Col span={col_a}></Col>
            <Col span={col_b}>
              <ReporteComponent showHeaders={false} dataFunc={dataFunc} url_reporte={this.url_reporte} titulo_reporte={this.titulo_reporte} columnas={columnas} filtros={this.filtros} />
            </Col>
            <Col span={col_c}></Col>
          </Row>
          <br />
        </div>
      </div >
    );

  }
}


export default observer(ReporteHorarioPunta);
